import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import en from '../locales/en.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'

Vue.use(VueI18n)

// ブラウザの言語設定を取得
const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0];

// サポートされている言語のリスト
const supportedLanguages = ['en', 'ja', 'ko'];

// ブラウザの言語がサポートされているか確認し、そうでなければ英語を使用
const userLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const i18n = new VueI18n({
  locale: userLanguage, // ユーザーの言語設定またはフォールバック言語
  fallbackLocale: 'en', // バックアップの言語
  messages: { en, ja, ko }
});

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')