<template>
    <div class="home-feature-content">
        <img :src="require(`@/assets/${imageSrc}`)" alt="Feature Image" class="feature-image" />
        <div class="content-wrapper">
            <div class="text-wrapper">
                <p class="text">{{ $t(text) }}</p>
            </div>
            <div class="description-wrapper">
                <p class="description">{{ $t(description) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeFeatureContent',
    props: {
        imageSrc: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
    .home-feature-content {
        border: 1px solid #E5E5E5;
        height: 600px;
        width: 340px;
        margin: 0 2%;
        cursor: pointer;
    }

    .feature-image {
        width: 340px;
        height: 340px;
        border: 1px solid #E5E5E5;
    }

    .content-wrapper {
        padding-top: 10px;
    }

    .text-wrapper {
        font-size: 16px;
        display:flex;
    }

    .description-wrapper {
        font-size: 20px;
    }

    .text, .description {
        padding: 0 10px;
        color: #6C6A6C;
    }

    @media (max-width: 768px) {
        .text-wrapper {
            font-size: 16px;
        }

        .description-wrapper {
            font-size: 20px;
        }
    }

    @media (max-width: 480px) {

    .home-feature-content {
        width: 96%;
        height: 125px;
        display: flex;
        border-style: none;
    }

    .feature-image {
        width: 125px;
        height: 125px;
        border: 1px solid #E5E5E5;
    }

    .text-wrapper {
        font-size: 12px;
    }

    .description-wrapper {
        font-size: 10px;
    }
}

</style>
