<template>
  <!-- header -->
  <header>
    <div class="header-logo">
      <img src="../../assets/keyocokyba_logo.png" alt="logo" @click="GoHome()">
    </div>
    <div class="header-logotype">
      <img src="../../assets/keyocokyba_logotype.png" alt="logo" @click="GoHome()">
    </div>
    <nav :class="{ 'show-menu': showMenu }">
      <div class="hamburger-menu" @click="toggleMenu">
        <div class="line" :class="{ 'crossed-1': showMenu }"></div>
        <div class="line" :class="{ 'crossed-2': showMenu }"></div>
        <div class="line" :class="{ 'crossed-3': showMenu }"></div>
      </div>
      <ul class="none-hamburger-menu" v-show="!showMenu">
        <li @click="GoPrivacyPolicy()">{{ $t('header.privacy_policy') }}</li>
        <li @click="GoTermsOfUse()">{{ $t('header.terms_of_service') }}</li>
        <li @click="GoContactUs()">{{ $t('header.contact_us') }}</li>
      </ul>
    </nav>
    <div v-if="showMenu" class="humberger-content" @click="toggleMenu">
      <ul>
        <li @click="GoPrivacyPolicy()">{{ $t('header.privacy_policy') }}</li>
        <li @click="GoTermsOfUse()">{{ $t('header.terms_of_service') }}</li>
        <li @click="GoContactUs()">{{ $t('header.contact_us') }}</li>
      </ul>
    </div>
  </header>
</template>
  
<script>
  export default {
    name: 'HeaderBar',
    data() {
      return {
        showMenu: false
      };
    },
    methods: {
      GoHome() {
        this.$router.push('/').catch(()=>{});
      },
      GoPrivacyPolicy() {
        this.$router.push('/privacy-policy').catch(()=>{});
      },
      GoTermsOfUse() {
        this.$router.push('/terms-of-use').catch(()=>{});
      },
      GoContactUs() {
        this.$router.push('/contact-us').catch(()=>{});
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      }
    }
  }
</script>

<style scoped>
/* header */
  header{
    position: sticky;
    top: 0;
    width: 100%;
    height: 66px;
    background: white;
    display: flex;
    z-index: 2;
    border-bottom: 1px solid #E5E5E5;
  }

  nav{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  ul{
    display: flex;
    list-style: none;
  }

  li{
    margin-right: 30px;
    color: #6C6A6C;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 12px;
  }

  .hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 24px;
    height: 18px;
    cursor: pointer;
    margin-right: 24px;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #6C6A6C;
    transition: transform 0.3s;
  }

  .crossed-1 {
    transform:translateY(8px) rotate(45deg);
    background-color: #6C6A6C;
    z-index: 2;
  }

  .crossed-2 {
    transform: translateY(-8px) rotate(-45deg);
    background-color: #6C6A6C;
    z-index: 2;
  }

  .crossed-3 {
    display:none;
  }

  .show-menu .hamburger-menu {
    display: flex;
  }

  .show-menu ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .show-menu li {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 14px;
  }

/* header-logo */
  .header-logo{
    text-align: center;
    display: inline-block;
    padding: 12px;
    z-index: 2;
  }

  .header-logo img{
    width: 42px;
    height: 42px;
  }

  /* header-logotype */
  .header-logotype{
    text-align: center;
    display: inline-block;
    padding: 16px 12px 8px 12px;
    z-index: 2;
    cursor: pointer;
  }

  .header-logotype img{
    height: 32px;
  }

  .humberger-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .humberger-content ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .humberger-content li {
    margin: 32px 0;
    font-size: 20px;
    color: #6C6A6C;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    header {
      height: 50px;
    }

    .header-logo img {
      width: 32px;
      height: 32px;
    }

    .header-logotype img {
      height: 24px;
    }

    li {
      margin-right: 20px;
      font-size: 14px;
    }

    .hamburger-menu {
      display: flex;
    }

    .none-hamburger-menu {
      display: none;
    }

    .show-menu ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .show-menu li {
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    header {
      height: 40px;
    }

    .header-logo img {
      width: 24px;
      height: 24px;
    }

    .header-logotype img {
      height: 16px;
    }

    li {
      margin-right: 10px;
    }
  }
</style>
