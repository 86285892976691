<template>
  <div id="home-screen">
    <HeaderBar />
    <HomeEyecatch />
    <div class="section-title">
      <h1>{{ $t('home_screen.title_feature') }}</h1>
    </div>
    <div class="section-description">
      <p>{{ $t('home_screen.subtitle_feature') }}</p>
    </div>
    <HomeFeatures />
    <FooterBar />
  </div>
</template>

<script>
import HeaderBar from '@/components/Common/HeaderBar.vue';
import HomeEyecatch from '@/components/HomeScreen/HomeEyecatch.vue';
import HomeFeatures from '@/components/HomeScreen/HomeFeatures/HomeFeatures.vue';
import FooterBar from '@/components/Common/FooterBar.vue';

  export default {
    name: 'HomeScreen',
    components: {
      HeaderBar,
      HomeEyecatch,
      HomeFeatures,
      FooterBar,
    },
  }
</script>
  
<style scoped>
  .section-title, .section-description {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .section-title h1 {
    font-size: 40px;
    font-weight: bold;
    color: #6C6A6C;
    margin: 16px 20px;
  }

  .section-description p {
    font-size: 16px;
    color: #6C6A6C;
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    .section-title h1 {
      font-size: 24px;
    }

    .section-description p {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    .section-title h1 {
      font-size: 20px;
      margin: 20px 12px 12px 12px;
    }

    .section-description p {
      font-size: 8px;
    }
  }
</style>
