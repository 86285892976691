<template>
  <div class="video-background">
    <video autoplay loop muted playsinline webkit-playsinline>
      <source src="../../assets/mv-eyecatch.mp4" type="video/mp4">
    </video>
    <div class="overlay-text">
      <h1 class="main-heading">{{ $t('home_eyecatch.welcome_message') }}</h1>
      <p>{{ $t('home_eyecatch.welcome_message_description') }}</p>
      <button class="download-button" @click="AppStoreLink">{{ $t('home_eyecatch.download_button') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeEyecatch',
  methods: {
    AppStoreLink() {
      const url = 'https://apps.apple.com/jp/app/keyocokyba/id6468663402'
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.video-background {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.video-background video {
  position: absolute;
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6C6A6C;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  width: 80%;
}

.overlay-text h1 {
  font-size: 42px;
  font-weight: bold;
}

.overlay-text p {
  font-size: 20px;
  white-space: pre-line;
}

.download-button {
  background-color: #CA963F;
  color: white;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.3);  
}

/* Responsive Styles */
@media (max-width: 768px) {
  .video-background {
    height: 400px;
  }

  .video-background video {
    height: 400px;
  }

  .overlay-text h1 {
    font-size: 24px;
  }

  .overlay-text p {
    font-size: 12px;
  }

  .download-button {
    font-size: 12px;
    padding: 12px 16px;
  }
}

@media (max-width: 480px) {
  .video-background {
    height: 300px;
  }

  .video-background video {
    height: 300px;
  }

  .overlay-text h1 {
    font-size: 20px;
  }

  .overlay-text p {
    font-size: 8px;
  }

  .download-button {
    font-size: 8px;
    padding: 8px 16px;
  }
}
</style>