<template>
    <!-- ここにuse_caseの画像を表示するHTMLを追加 -->
    <div class="how-to-use">
      <div class="image-container">
        <img src="../assets/how-to-use-1.png" alt="Image 1">
        <img src="../assets/how-to-use-2.png" alt="Image 2">
        <img src="../assets/how-to-use-3.png" alt="Image 3">
        <img src="../assets/how-to-use-4.png" alt="Image 4">
        <img src="../assets/how-to-use-5.png" alt="Image 5">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HowToUseEn'
  }
  </script>
  
  <style scoped>
  .image-container {
    display: flex;
    flex-direction: column; /* 画像を縦並びにする */
    align-items: center; /* 画像を中央寄せにする */
  }

  .image-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px; /* 画像間の余白 */
  }

  @media (min-width: 600px) {
    .image-container img {
      max-width: 30%; /* デスクトップでは横幅30% */
    }
  }
  </style>