<template>
    <div id="detail-location">
        <HeaderBar />
        <div class="section-title">
            <h1>{{ $t('detail_location.section_title') }}</h1>
        </div>
        <div class="location-content-wrapper">
            <LocationContent 
                imageSrc="detail_location_step1.png"
                text="detail_location.step1_title"
                description="detail_location.step1_description"
            />
            <LocationContent 
                imageSrc="detail_location_step2.png"
                text="detail_location.step2_title"
                description="detail_location.step2_description"
            />
            <LocationContent 
                imageSrc="detail_location_step3.png"
                text="detail_location.step3_title"
                description="detail_location.step3_description"
            />
            <LocationContent 
                imageSrc="detail_location_step4.png"
                text="detail_location.step4_title"
                description="detail_location.step4_description"
            />
            <LocationContent 
                imageSrc="detail_location_step5.png"
                text="detail_location.step5_title"
                description="detail_location.step5_description"
            />
        </div>
        <FooterBar />
    </div>
</template>

<script>
import HeaderBar from '@/components/Common/HeaderBar.vue';
import FooterBar from '@/components/Common/FooterBar.vue';
import LocationContent from '@/components/Details/Location/LocationContent.vue';

export default {
    name: 'DetailLocation',
    components: {
        HeaderBar,
        FooterBar,
        LocationContent
    },
}

</script>

<style scoped>
    .section-title {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .section-title h1 {
        font-size: 40px;
        font-weight: bold;
        color: #6C6A6C;
        margin: 32px 20px;
    }
    .location-content-wrapper {
        width: 60%;
        margin: 0 auto 50px auto;
    }

    @media screen and (max-width: 768px) {
        .section-title h1 {
            font-size: 30px;
        }
        .location-content-wrapper {
            width: 80%;
        }
    }

    @media screen and (max-width: 480px) {
        .section-title h1 {
            font-size: 20px;
            margin: 16px 10px 0 10px;
        }
        .location-content-wrapper {
            width: 90%;
            margin: 8px auto;
        }
    }
</style>