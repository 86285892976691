import Vue from 'vue'
import Router from 'vue-router'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import HowToUseEn from '@/pages/HowToUseEn'
import HowToUseJa from '@/pages/HowToUseJa'
import HowToUseKo from '@/pages/HowToUseKo'
import HomeScreen from '@/pages/HomeScreen'
import DetailLocation from '@/pages/DetailLocation'
import ContactUs from '@/pages/ContactUs'
import TermsOfUse from '@/pages/TermsOfUse'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomeScreen',
      component: HomeScreen
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: TermsOfUse
    },
    {
      path: '/detail-location',
      name: 'DetailLocation',
      component: DetailLocation
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
        path: '/how-to-use',
        name: 'HowToUseEn',
        component: HowToUseEn
    },
    {
        path: '/how-to-use/ja',
        name: 'HowToUseJa',
        component: HowToUseJa
    },
    {
        path: '/how-to-use/ko',
        name: 'HowToUseKo',
        component: HowToUseKo
    },
  ]
})