<template>
    <div class="home-features">
        <div class="trigger-location trigger" @click="GoDetailLocation()">
            <HomeFeatureContent 
                imageSrc="trigger-location.png"
                text="home_feature.location_trigger"
                description="home_feature.location_trigger_description"
            />
        </div>
        <div class="trigger-tags trigger">
            <HomeFeatureContent 
                imageSrc="trigger-tags.png"
                text="home_feature.tag_trigger"
                description="home_feature.tag_trigger_description"
            />
        </div>
        <div class="trigger-date-time trigger">
            <HomeFeatureContent 
                imageSrc="trigger-date-time.png"
                text="home_feature.date_time_trigger"
                description="home_feature.date_time_trigger_description"
            />
        </div>
    </div>
</template>

<script>
import HomeFeatureContent from './HomeFeatureContent.vue';

export default {
    name: 'HomeFeatures',
    components: {
        HomeFeatureContent
    },
    methods: {
        GoDetailLocation(){
            this.$router.push('/detail-location').catch(()=>{});
        }
    }
}
</script>

<style scoped>
.home-features {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 2.5%;
    z-index: 1;
}

.trigger {
    margin: 16px 2%;
}

@media (max-width: 1148px) {
    .home-features {
        flex-wrap: wrap;
    }
}

@media (max-width: 480px) {
    .home-features {
        margin: 16px 2.5%;
    }
}
</style>
