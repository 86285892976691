<template>
    <div class="footer-bar">
        <div class="footer-text" @click="GoPrivacyPolicy()">{{ $t('footer.privacy_policy') }}</div>
        <div class="footer-text" @click="GoTermsOfUse()">{{ $t('footer.terms_of_service') }}</div>
        <div class="footer-text" @click="GoContactUs()">{{ $t('footer.contact_us') }}</div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderBar',
        methods: {
            GoPrivacyPolicy() {
                this.$router.push('/privacy-policy').catch(()=>{});
            },
            GoTermsOfUse() {
                this.$router.push('/terms-of-use').catch(()=>{});
            },
            GoContactUs() {
                this.$router.push('/contact-us').catch(()=>{});
            },
        }
    }
</script>

<style scoped>
.footer-bar {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #E5E5E5;
    margin-top: 100px;
}

.footer-text {
    color: #6C6A6C;
    font-size: 12px;
    padding: 32px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .footer-bar {
        flex-direction: column;
        height: auto;
        margin-top: 50px;
    }

    .footer-text {
        padding: 16px;
    }
}
</style>
