<template>
    <div class="">
        <div class="text">
            {{ $t(text) }}
        </div>
        <div class="description">
            {{ $t(description) }}
        </div>
        <div class="image">
            <img :src="require(`@/assets/${imageSrc}`)" alt="Location Image" class="location-image" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        imageSrc: {
            type: String,
            default: ""
        }
    }
};
</script>

<style scoped>
    .text, .description {
        padding: 16px 0;
        color: #6C6A6C;
    }

    .text {
        font-size: 20px;
        font-weight: bold;
    }

    .image img{
        width: 100%;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        .text {
            font-size: 16px;
        }
        .description {
            font-size: 14px;
        }
        .image img{
            width: 100%;
            height: auto;
        }
    }

    @media screen and (max-width: 480px) {
        .text {
            font-size: 12px;
        }
        .description {
            font-size: 10px;
        }
        .image img{
            width: 100%;
            height: auto;
        }
    }
</style>
