<template>
    <div class="terms-of-use">
      <HeaderBar />
      <div class="terms-of-use-app">
        <article>
          <h1>{{ $t('terms_of_use.title') }}</h1>
          <p><span id="company"></span>{{ $t('terms_of_use.description') }}</p>
      
          <h2>{{ $t('terms_of_use.definition_title') }}</h2>
          <p>{{ $t('terms_of_use.definition_subtitle') }}</p>

          <ol>
            <li>{{ $t('terms_of_use.definition_content1') }}</li>
            <li>{{ $t('terms_of_use.definition_content2') }}</li>
            <li>{{ $t('terms_of_use.definition_content3') }}</li>
          </ol>
      
          <h2>{{ $t('terms_of_use.use_of_our_services_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.use_of_our_services_content1') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content2') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content3') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content4') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content5') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content6') }}</li>
            <li>{{ $t('terms_of_use.use_of_our_services_content7') }}</li>
          </ol>
      
          <h2>{{ $t('terms_of_use.restriction_on_use_of_our_services_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.restriction_on_use_of_our_services_content1') }}</li>
            <li>{{ $t('terms_of_use.restriction_on_use_of_our_services_content2') }}</li>
          </ol>
      
          <h2>{{ $t('terms_of_use.account_registration_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.account_registration_content1') }}</li>
            <li>{{ $t('terms_of_use.account_registration_content2') }}</li>
          </ol>
      
          <h2>{{ $t('terms_of_use.form_of_provision_of_the_service_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content1') }}</li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content2') }}</li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content3') }}</li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4') }}</li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4_1') }}  </li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4_2') }}  </li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4_3') }}  </li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4_4') }}  </li>
            <li>{{ $t('terms_of_use.form_of_provision_of_the_service_content4_5') }}  </li>            
          </ol>
      
          <h2>{{ $t('terms_of_use.content_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.content_content1') }}</li>
            <li>{{ $t('terms_of_use.content_content2') }}</li>
            <li>{{ $t('terms_of_use.content_content3') }}</li>
          </ol>
      
          <h2>{{ $t('terms_of_use.handling_of_customer_data_and_content_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.handling_of_customer_data_and_content_content1') }}</li>
            <li>{{ $t('terms_of_use.handling_of_customer_data_and_content_content2') }}</li>
            <li>{{ $t('terms_of_use.handling_of_customer_data_and_content_content3') }}</li>
            <li>{{ $t('terms_of_use.handling_of_customer_data_and_content_content4') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.user_information_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.user_information_content1') }}</li>
            <li>{{ $t('terms_of_use.user_information_content1_1') }}</li>
            <li>{{ $t('terms_of_use.user_information_content1_2') }}</li>
            <li>{{ $t('terms_of_use.user_information_content1_3') }}</li>
            <li>{{ $t('terms_of_use.user_information_content1_4') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_1') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_2') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_3') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_4') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_5') }}</li>
            <li>{{ $t('terms_of_use.user_information_content2_6') }}</li>
            <li>{{ $t('terms_of_use.user_information_content3') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.customer_responsibility_title') }}</h2>
          <p>{{ $t('terms_of_use.customer_responsibility_content') }}</p>
          
          <h2>{{ $t('terms_of_use.warranty_and_modification_of_our_service_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.warranty_and_modification_of_our_service_content1') }}</li>
            <li>{{ $t('terms_of_use.warranty_and_modification_of_our_service_content2') }}</li>
            <li>{{ $t('terms_of_use.warranty_and_modification_of_our_service_content2_1') }}</li>
            <li>{{ $t('terms_of_use.warranty_and_modification_of_our_service_content2_2') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.prohibition_of_reuse_of_our_services_title') }}</h2>
          <p>{{ $t('terms_of_use.prohibition_of_reuse_of_our_services_content') }}</p>

          <h2>{{ $t('terms_of_use.compliance_in_use_of_our_services_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_sub_title1') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content1') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content2') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content3') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content4') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content5') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content6') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content7') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content8') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content9') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content10') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content11') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content12') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content13') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content14') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content15') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content16') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_content17') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_sub_title2') }}</li>
            <li>{{ $t('terms_of_use.compliance_in_use_of_our_services_sub_title3') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.prohibition_of_assignment_of_rights_and_obligations_title') }}</h2>
          <p>{{ $t('terms_of_use.prohibition_of_assignment_of_rights_and_obligations_content') }}</p>

          <h2>{{ $t('terms_of_use.deletion_of_content_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.deletion_of_content_sub_title') }}</li>
            <li>{{ $t('terms_of_use.deletion_of_content_content1') }}</li>
            <li>{{ $t('terms_of_use.deletion_of_content_content2') }}</li>
            <li>{{ $t('terms_of_use.deletion_of_content_content3') }}</li>
            <li>{{ $t('terms_of_use.deletion_of_content_content4') }}</li>
            <li>{{ $t('terms_of_use.deletion_of_content_content5') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.indemnification_title') }}</h2>
          <p>{{ $t('terms_of_use.indemnification_content') }}</p>

          <h2>{{ $t('terms_of_use.disclaimer_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.disclaimer_content1') }}</li>
            <li>{{ $t('terms_of_use.disclaimer_content2') }}</li>
            <li>{{ $t('terms_of_use.disclaimer_content3') }}</li>
            <li>{{ $t('terms_of_use.disclaimer_content4') }}</li>
            <li>{{ $t('terms_of_use.disclaimer_content5') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.services_of_third_parties_title') }}</h2>
          <p>{{ $t('terms_of_use.services_of_third_parties_content') }}</p>

          <h2>{{ $t('terms_of_use.notices_or_communications_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.notices_or_communications_content1') }}</li>
            <li>{{ $t('terms_of_use.notices_or_communications_content2') }}</li>
            <li>{{ $t('terms_of_use.notices_or_communications_content3') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.privacy_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.privacy_content1') }}</li>
            <li>{{ $t('terms_of_use.privacy_content2') }}</li>
            <li>{{ $t('terms_of_use.privacy_content3') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.revision_of_terms_of_use_title') }}</h2>
          <ol>
            <li>{{ $t('terms_of_use.revision_of_terms_of_use_content1') }}</li>
            <li>{{ $t('terms_of_use.revision_of_terms_of_use_content2') }}</li>
            <li>{{ $t('terms_of_use.revision_of_terms_of_use_content3') }}</li>
            <li>{{ $t('terms_of_use.revision_of_terms_of_use_content4') }}</li>
          </ol>

          <h2>{{ $t('terms_of_use.governing_law_and_jurisdiction_title') }}</h2>
          <p>{{ $t('terms_of_use.governing_law_and_jurisdiction_content') }}</p>

          <h2>{{ $t('terms_of_use.limitation_on_application_of_terms_of_use_title') }}</h2>
          <p>{{ $t('terms_of_use.limitation_on_application_of_terms_of_use_content') }}</p>

          <p>{{ $t('terms_of_use.date') }}</p>
          <p>{{ $t('terms_of_use.version') }}</p>
          <hr>
          <h2>{{ $t('terms_of_use.company_overview') }}</h2>
          <p>{{ $t('terms_of_use.company_overview_name') }}</p>
          <p>{{ $t('terms_of_use.company_overview_director') }}</p>
          <p>{{ $t('terms_of_use.company_overview_address') }}</p>
          <p>{{ $t('terms_of_use.company_overview_business') }}</p>
          <p>{{ $t('terms_of_use.company_overview_established') }}</p>
          <hr>
          <h2>{{ $t('terms_of_use.revision_history') }}</h2>
          <p>{{ $t('terms_of_use.revision_history_content') }}</p>
          <p>{{ $t('terms_of_use.revision_current_version') }}</p>
          <p>{{ $t('terms_of_use.revision_developed') }}</p>
          <p>{{ $t('terms_of_use.revision_version') }}</p>
      </article>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import HeaderBar from '@/components/Common/HeaderBar.vue';
import FooterBar from '@/components/Common/FooterBar.vue';

export default {
  name: 'TermsOfUse',
  components: {
    HeaderBar,
    FooterBar,
  },
}

</script>

<style scoped>
  .terms-of-use-app {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    width: 60%;
    margin: 50px auto;
  }
  
  article {
    padding: 0 16px;
  }
  
  article h1 {
    font-size: 1.4em;
  }
  
  article h2 {
    font-size: 1.2em;
  }
  
  article p {
    font-size: 1em;
  }
  
  article li {
    font-size: 1em;
    list-style: none;
  }

  @media (max-width: 768px) {
    .terms-of-use-app {
      width: 80%;
    }
  }

  @media (max-width: 480px) {
    .terms-of-use-app {
      width: 90%;
    }
  }
  
</style>
  