<template>
    <div id="contact-us">
        <HeaderBar />
        <div class="video-background">
            <video autoplay loop muted>
                <source src="../assets/mv-eyecatch.mp4" type="video/mp4">
            </video>
            <!-- Contact Us -->
            <div class="overlay-text" v-if="!completeFlg">
                <h1 class="contact-heading">{{ $t('contact_us.title') }}</h1>
                <form>
                    <div class="form-group">
                        <label for="username">{{ $t('contact_us.name') }}</label>
                        <span v-if="usernameError" class="error-message">{{ $t('contact_us.name_error') }}</span>
                        <input type="text" id="username" v-model="username" />
                        
                    </div>
                    <div class="form-group">
                        <label for="email">{{ $t('contact_us.email') }}</label>
                        <span v-if="emailError" class="error-message">{{ $t('contact_us.email_error') }}</span>
                        <input type="email" id="email" v-model="email" />
                    </div>
                    <div class="form-group">
                        <label for="comment">{{ $t('contact_us.comment') }}</label>
                        <span v-if="commentError" class="error-message">{{ $t('contact_us.comment_error') }}</span>
                        <textarea id="comment" rows="5" v-model="comment"></textarea>
                    </div>
                    <button class="send-button" @click="validateForm">{{ $t('contact_us.send') }}</button>
                </form>
            </div>
            <!-- SendEmail completed -->
            <div class="overlay-text" v-if="completeFlg">
                <h1 class="contact-heading completed-heading" style="margin-bottom: 64px;">{{ $t('contact_us.success') }}</h1>
                <button class="send-button">{{ $t('contact_us.send') }}</button>
            </div>
        </div>
        <FooterBar style="margin-top: 0;"/>
    </div>
</template>

<script>
import HeaderBar from '@/components/Common/HeaderBar.vue';
import FooterBar from '@/components/Common/FooterBar.vue';

export default {
    name: 'ContactUs',
    components: {
        HeaderBar,
        FooterBar
    },
    data() {
        return {
            username: '',
            email: '',
            comment: '',
            usernameError: false,
            emailError: false,
            commentError: false,
            completeFlg: false,
        };
    },
    methods: {
        validateForm(event) {
            event.preventDefault();
            this.usernameError = false;
            this.emailError = false;
            this.commentError = false;
            const isValidEmail = this.isValidEmail(this.email);
            console.log('isValidEmail', isValidEmail);

            if (!this.username || !this.email || !isValidEmail || !this.comment) {
                // Display error message for required fields
                if (!this.username) {
                    this.usernameError = true;
                }
                if (!this.email || !isValidEmail) {
                    this.emailError = true;
                }
                if (!this.comment) {
                    this.commentError = true;
                }
                return;
            }else {
                // Form is valid, submit the form
                console.log('Form is valid')
                this.submitForm();
            }
        },
        isValidEmail(email) {
            // Email validation logic
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        submitForm() {
            // Add your logic here for sending the form data
            alert('Form submitted successfully');
            this.completeFlg = true;
        }
    }
}
</script>

<style scoped>
    .video-background {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    .video-background video {
        position: absolute;
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .overlay-text {
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .overlay-text h1 {
        font-size: 42px;
        font-weight: bold;
        text-align: center;
        color: #6C6A6C;
        text-shadow: 4px 4px 8px rgba(255, 255, 255, 0.7); 
        margin: 12px 0;
    }
    .send-button {
        background-color: #CA963F;
        color: white;
        padding: 16px 20px;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        margin-top: 20px;
        cursor: pointer;
        box-shadow: 10px 10px 10px rgba(0,0,0,0.3);
        width: 100%;
    }
    form {
        width: 100%;
        height: 100%;
        padding: 10px;

    }
    .form-group {
        margin-bottom: 20px;
    }
    label {
        display: inline;
        font-weight: bold;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
        margin-bottom: 16px;
    }
    input,
    textarea {
        width: 95%;
        padding: 2.5%;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    textarea {
        height: 150px;
    }
    .error-message {
        color: red;
        font-size: 16px;
        margin: 5px 0 0 12px;
        display: inline;
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); 
    }

    .completed-heading {
        margin-bottom: 100px;
    }

    @media (max-width: 768px) {
        .overlay-text {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 47.5%;
            transform: translate(-50%, -50%);
        }
        .overlay-text h1 {
            font-size: 24px;
        }
        .send-button {
            font-size: 14px;
        }
        input,
        textarea {
            font-size: 12px;
        }
        label {
            font-size: 12px;
        }
        textarea {
            height: 200px;
        }
        .error-message {
            font-size: 12px;
        }
    }

    @media (max-width: 480px) {
        .overlay-text {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 47.5%;
            transform: translate(-50%, -50%);
        }
        .overlay-text h1 {
            font-size: 20px;
        }
        .send-button {
            font-size: 12px;
        }
        input,
        textarea {
            font-size: 12px;
        }
        textarea {
            height: 160px;
        }
        .error-message {
            font-size: 10px;
        }
    }
</style>