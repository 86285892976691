<template>
    <div class="privacy-policy">
      <HeaderBar />
      <div class="privacy-policy-app">
        <article>
          <h2>{{ $t('privacy_policy.overview') }}</h2>
          <p><span id="company"></span>{{ $t('privacy_policy.overview_content') }}</p>
      
          <h2>{{ $t('privacy_policy.information_collection') }}</h2>
          <p>{{ $t('privacy_policy.information_collection_content') }}</p>
      
          <h2>{{ $t('privacy_policy.information_storage') }}</h2>
          <p>{{ $t('privacy_policy.information_storage_content') }}</p>
      
          <h2>{{ $t('privacy_policy.sharing_information') }}</h2>
          <p>{{ $t('privacy_policy.sharing_information_content') }}</p>
      
          <h2>{{ $t('privacy_policy.user_rights') }}</h2>
          <ol>
            <li>{{ $t('privacy_policy.user_rights_content_1') }}</li>
            <li>{{ $t('privacy_policy.user_rights_content_2') }}</li>
            <li>{{ $t('privacy_policy.user_rights_content_3') }}</li>
          </ol>
      
          <h2>{{ $t('privacy_policy.security') }}</h2>
          <p>{{ $t('privacy_policy.security_content') }}</p>
      
          <h2>{{ $t('privacy_policy.modification') }}</h2>
          <ol>
            <li>{{ $t('privacy_policy.modification_content_1') }}</li>
            <li>{{ $t('privacy_policy.modification_content_2') }}</li>
          </ol>
      
          <h2>{{ $t('privacy_policy.article') }}</h2>
          <p>{{ $t('privacy_policy.article_content') }}</p>
          <p>{{ $t('privacy_policy.address') }}<span id="address"></span><br>
            {{ $t('privacy_policy.company_name') }}<span id="company2"></span><br>
            {{ $t('privacy_policy.email') }}<span id="email"></span></p>
        <p>{{ $t('privacy_policy.above') }}</p>
      </article>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import HeaderBar from '@/components/Common/HeaderBar.vue';
import FooterBar from '@/components/Common/FooterBar.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    HeaderBar,
    FooterBar
  },
}

</script>

<style scoped>
  .privacy-policy-app {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    width: 60%;
    margin: 20px auto;
  }
  
  article {
    padding: 0 16px;
  }
  
  article h1 {
    font-size: 1.4em;
  }
  
  article h2 {
    font-size: 1.2em;
  }
  
  article p {
    font-size: 1em;
  }
  
  article li {
    font-size: 1em;
  }

  @media (max-width: 768px) {
    .privacy-policy-app {
      width: 80%;
    }
  }

  @media (max-width: 480px) {
    .privacy-policy-app {
      width: 90%;
    }
  }
  
</style>
  